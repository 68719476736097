//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: "SectionBillboardButton",
  components: {
    Button: () => import('@/components/common/Button')
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    background: {
      type: String,
      default: () => 'var(--accent-color)'
    },
    color: {
      type: String,
      default: () => 'var(--white)'
    },
    method: {
      type: String,
      default: () => null
    }
  },
  computed: {
    ...mapGetters({
      logged: 'user/logged'
    }),
  },
  methods: {
    click() {
      this[this.method](this.logged)
    }
  }
}
